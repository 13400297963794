import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// @mui
import { Container, Grid } from '@mui/material';

// sections
import { usePaymentsService } from '@src/store/hooks/usePaymentsService';
import {
  AppWidgetSummary,
  UserEngagementsChart,
} from '../sections/@dashboard/app';

import { useAccessControlContext } from '@src/context/AccessControl';
import { Access } from '@src/lib/types/access-control';
import { pluck } from '@src/utils/pluck';
import { useGamesService } from './games/hooks';
import { useUsersService } from './users/hooks';
import { fCurrency } from '@src/utils/formatNumber';
import palette from '@src/theme/palette';
import Config from '@src/utils/api/config';
import MostPlayedGames from './most-played/mini-view';

const { isFortune8 } = Config;

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const { state } = useAccessControlContext();
  const { totalPurchasesThisMonth } = usePaymentsService();
  
  const { totalUsers, totalUsersThisMonth } = useUsersService({ fetchTotalUsers: true, fetchTotalUsersThisMonth: true });
  const { totalGames } = useGamesService({ getTotalGames: true, getMostPopularGame: true });
  const myAccess: Access['dashboard'] = pluck(state, 'access.dashboard');
  const myRevShareAccess: Access['revenueShare'] = pluck(state, 'access.revenueShare');

  const {
    allowViewPrograms,
    allowViewTotalGames,
    allowViewTotalUsers,
    allowViewTotalNewUsersMonthly,
    allowViewTotalNumberOfPurchases,
    allowViewMostPopularGame,
    allowViewEngagementGraph
  } = myAccess || {};

  const { allowView } = myRevShareAccess || {};

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {allowViewTotalNumberOfPurchases && <Grid item xs={12} sm={6} md={3} >
            <AppWidgetSummary
              onClick={() => navigate('/dashboard/purchase')}
              title="Purchases this month"
              value={fCurrency(totalPurchasesThisMonth?.totalAmount)}
              color={palette.green.dark}
              icon={'streamline:bag-dollar-solid'}
            />
          </Grid>}

          {allowViewTotalUsers && <Grid item xs={12} sm={6} md={3} >
            <AppWidgetSummary
              onClick={() => navigate('/dashboard/users')}
              title="Total Users"
              value={totalUsers}
              color={palette.DarkTurquoise.dark}
              icon={'fa-solid:users'}
            />
          </Grid>}

          {allowViewTotalNewUsersMonthly && <Grid item xs={12} sm={6} md={3} >
            <AppWidgetSummary
              onClick={() => navigate('/dashboard/users')}
              title="New Users This Month"
              value={totalUsersThisMonth}
              color={palette.peach.dark}
              icon={'mdi:users-plus'}
            />
          </Grid>}

          {allowViewTotalGames && <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              onClick={() => navigate('/dashboard/games')}
              title="Total Games"
              color={palette.blue.dark}
              value={totalGames}
              icon={'ion:game-controller'}
            />
          </Grid>}
        </Grid>
        {allowViewEngagementGraph && <UserEngagementsChart />}

        <MostPlayedGames />
      </Container>
    </>
  );
}
