import { Box, Typography } from '@mui/material';
import _sortBy from 'lodash/sortBy';

import useCoinTransactionBetStats from '@src/hooks/useCoinTransactionBetStatsService';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { useMemo } from 'react';

const UserEngagementsChart = () => {
	const { data } = useCoinTransactionBetStats();

	const sortedData = useMemo(() => {
			return _sortBy(data?.days || [], 'date');
	}, [data]);

	return (
		<Box mt={5}>
			<Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
				Engagement Activity
			</Typography>
			<Box p={"20px"} borderRadius={"25px"} display={"flex"} bgcolor={"white"}>
				<ComposedChart
					width={1500}
					height={400}
					data={sortedData}
					margin={{
							top: 20,
							right: 20,
							bottom: 20,
							left: 20,
					}}>
					<CartesianGrid stroke="#f5f5f5" />
					<XAxis
						dataKey="date"
						angle={-45}
						textAnchor="end"
						height={70}
						dy={10}
					/>
					<YAxis
						label={{ value: 'Total number of users', angle: -90, position: 'insideLeft', dy: 80, dx: -20 }}
					/>
					<Tooltip />
					<Area type="monotone" dataKey="numBetUsers" fill="#8884d8" stroke="#8884d8" />
				</ComposedChart>
			</Box>
		</Box>
	)
}

export default UserEngagementsChart