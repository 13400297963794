import { Helmet } from "react-helmet-async";
import Header from "./header";

export default function PlatformTools() {
	return (
		<>
			<Helmet>
				<title> Platform Tools </title>
			</Helmet>
			<Header />
		</>
	)
}